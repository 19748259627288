<template>
  <component :is="iconComponent" />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

const props = defineProps<{ name: string }>()

const generateIconName = (iconName: string): any => {
  const iconUrls = import.meta.glob('../../assets/icons/**/*.svg')
  const icons = Object.entries(iconUrls).reduce((acc, [key, value]) => {
    const name = key.split('../../')[1].replace('assets/icons/', '').replace('.svg', '')
    return { ...acc, [name]: value }
  }, {})
  return icons[iconName]
}

const iconComponent = defineAsyncComponent(async () => {
  const icon = generateIconName(props.name)
  if (!icon) throw new Error(`Icon ${props.name} not found`)
  return await icon()
})
</script>
